<template>
  <div class="milepost-home">
    <edp-header
      class="header-bg"
      :headerLeftWord="$t('member.EmpMilestonereport')"
    ></edp-header>

    <div class="home-wrap">
      <p class="f60 fb tac">
        {{ $t("member.emp") }}
        <span class="cg">{{ $t("member.EmpMilestonereport") }}</span>
      </p>
      <p class="f32 tac">{{ $t("member.MilesDesc") }}</p>
      <div class="report-wrap">
        <a
          href="javascript:void(0);"
          @click="$router.push({ name: 'milepostReport', query: { type: 2 } })"
        >
          <div class="report-item">
            <img src="@/src/assets/member/rep-02-new.png" alt="" />
            <p class="f26 cf mb8">{{ $t("member.Annualreport") }}</p>
            <div class="mask"></div>
          </div>
        </a>
        <a
          href="javascript:void(0);"
          @click="$router.push({ name: 'milepostReport', query: { type: 3 } })"
        >
          <div class="report-item">
            <img src="@/src/assets/member/rep-05-new.png" alt="" />
            <p class="f26 cf mb8">{{ $t("member.Birthdayreport") }}</p>
            <div class="mask"></div>
          </div>
        </a>

        <!--@click="$router.push({ name: 'milepostReport', query: { type: 1 } })"-->
        <a
          href="javascript:void(0);"
          @click="$router.push({ name: 'annualReport' })"
        >
          <div class="report-item">
            <img src="@/src/assets/member/rep-01-new.png" alt="" />
            <p class="f26 cf mb8" v-html="$t('member.Yearlyreport')"></p>
            <div class="mask"></div>
            <!-- <div class="tag f20 cf tac">{{ $t("member.Comingsoon") }}</div> -->
          </div>
        </a>

        <!--<div class="report-item">
          <img src="@/src/assets/member/rep-03.png" alt="" />
          <p class="f26 cf mb8">{{ $t("member.Promotionr") }}</p>
          <div class="mask"></div>
          <div class="tag f20 cf tac">{{ $t("member.Comingsoon") }}</div>
        </div>
        <div class="report-item">
          <img src="@/src/assets/member/rep-04.png" alt="" />
          <p class="f26 cf mb8">{{ $t("member.Performancer") }}</p>
          <div class="mask"></div>
          <div class="tag f20 cf tac">{{ $t("member.Comingsoon") }}</div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  mounted() {},
  methods: {
    searchFn(data) {
      console.log(data);
    },
    onDetail() {
      this.$router.push("/memberDetail");
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
.milepost-home {
  width: 100%;
  height: 100vh;
  background: url("../assets/member/reph-bg.png") no-repeat;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .home-wrap {
    width: 100%;
    //height: 100vh;
    padding-top: 10vh;
    flex-basis: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .report-wrap {
      display: flex;
      justify-content: center;
      //width: 100%;
      padding: 0 0;
      margin: 8vh auto;
      .report-item {
        position: relative;
        width: toPad(300);
        height: toPad(350);
        padding: toPad(24) 0;
        margin: 0 toPad(15);

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        p {
          position: relative;
          display: block;
          width: 50%;
          z-index: 5;
          margin-left: toPad(18);
        }
        .mask {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 3;
          width: 100%;
          height: 100%;
          background: rgba($color: #000000, $alpha: 0.1);
        }
        .tag {
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 6;
          min-width: toPad(103);
          height: toPad(48);
          line-height: toPad(48);
          padding: 0 toPad(10);
          background: #3e886d;
          border-radius: 5px 0px 0px 0px;
        }
      }
    }
  }
}
</style>
